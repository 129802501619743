import * as React from "react"
import styled from "styled-components"
import { Global, css } from "@emotion/react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Header from "../components/header"
import { StaticImage } from "gatsby-plugin-image"

const AnnualStyle = styled.section`
  font-family: "Roboto";
  font-style: normal;
  background: linear-gradient(180deg, #e3f9e5 0%, #faf9f7 54.69%);
  color: rgb(80, 74, 64);
`

const Title = styled.h1`
  text-align: center;
  font-size: 40px;
  margin-bottom: 20px;
  padding: 0 3.5%;
  color: rgb(66, 61, 51);
`

const ImageRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 3.5%;
  margin-bottom: 20px;
  .img1 {
    width: 314px;
    height: 104px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin: 0 24px;
    padding-right: 32px;
  }

  .img2 {
    width: 365px;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin: 0 24px;
  }
  .img3 {
    width: 276px;
    height: 197px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin: 0 24px;
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    .break2 {
      /* flex-basis: 100%; */
    }
    .img1,
    .img2 {
      width: 40%;
      margin: 24px;
      margin-bottom: 0;
    }
    .img3 {
      width: 40%;
      margin: 24px;
    }
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    .break2 {
      /* flex-basis: 100%; */
    }
    .img1,
    .img2,
    .img3 {
      width: 50%;
      margin: 24px;
    }
  }
`

const Body = styled.div`
  padding: 0 3.5%;
  max-width: 1025px !important;
  margin: auto;
  .center {
    margin-bottom: 16px;
  }
  padding-bottom: 64px;
`

const IndexPage = props => {
  return (
    <AnnualStyle>
      <Global
        styles={css`
          body {
            margin: 0;
          }
          html {
            margin: 0;
            overflow-x: hidden;
          }
        `}
      />
      <Header></Header>
      <Title>Projekt: „Projekt Integrator tvrtke INTIS Engineering”</Title>
      <ImageRow>
        <div className="img1">
          <StaticImage
            src="../images/supported_by/ESI.png"
            layout="constrained"
          />
        </div>
        <div className="break1" />
        <div className="img2">
          <StaticImage
            src="../images/supported_by/OPKK.png"
            layout="constrained"
          />
        </div>
        <div className="break2" />
        <div className="img3">
          <StaticImage
            src="../images/supported_by/EU_amblem.png"
            layout="constrained"
          />
        </div>
      </ImageRow>
      <Body>
        <div className="center">
          <b>
            Tvrtka Ascalia d.o.o. kao partner sudjeluje u provedbi projekta
            Integrator tvrtke INTIS Engineering
          </b>
        </div>
        <div className="center">
          <b>
            Projekt sufinancira Europska unija iz Europskog fonda za regionalni
            razvoj koji se provodi u sklopu Operativnog programa Konkurentnost i
            kohezija 2014-2020
          </b>
        </div>
        <p>
          <b>Korisnik projekta:</b> INTIS ENGINEERING d.o.o., Ulica Bani 73/A,
          Buzin, 10010 Zagreb; OIB: 63134223890
        </p>

        <p>
          <strong>Partneri:</strong>
        </p>

        <p>1.IMG ZAGREB d.o.o., Prigornica 2, 10000 Zagreb; OIB: 24665884283</p>

        <p>2.ASCALIA d.o.o., Ulica Trate 16. 40000 Čakovec; OIB: 08686669752</p>

        <ul>
          <li>
            <strong>Kratki opis projekta</strong>
          </li>
        </ul>

        <p>
          Tvrtka INTIS ENGINEERING d.o.o. potpisala je s Ministarstvom
          gospodarstva i održivog razvoja te Hrvatskom agencijom za malo
          gospodarstvo, inovacije i investicije (HAMAG BICRO) Ugovor o dodjeli
          bespovratnih sredstava broj KK.03.2.2.05.0023 za provedbu projekta{" "}
          <em>„Projekt Integrator tvrtke INTIS Engineering”.</em>
        </p>

        <p>
          Predmet projekta je ulaganje u inovaciju poslovnih procesa i
          organizacije poslovanja konzorcija s ciljem postizanja dugoročnih
          dobavljačkih odnosa s tvrtkama Integratorima komercijalizacijom
          inovativnog IKS sustava. Partneri na projektu i članovi konzorcija su
          tvrtke IMG Zagreb d.o.o. te Ascalia d.o.o.
        </p>

        <ul>
          <li>
            <strong>Razdoblje provedbe projekta</strong>
          </li>
        </ul>

        <p>
          Projekt se provodi od 01. siječnja 2021. godine do 01. siječnja 2023.
          godine.
        </p>

        <ul>
          <li>
            <strong>Opći cilj projekta</strong>
          </li>
        </ul>

        <p>
          Opći cilj projekta je podržati suradnju MSP-ova kako bi kroz stvaranje
          novih inovativnih proizvoda i usluga uspostavili dobavljačke obnose sa
          poduzećima Integratorima i postali dio njihovog lanca vrijednosti u
          ciljanom strateškom segmentu, odnosno podržati inovaciju procesa i/ili
          poslovanja organizacije konzorcija MPS-ova za uspostavljanje
          dugoročnih dobavljačkih odnosa/lanca vrijednosti sa Integratorima.
        </p>

        <ul>
          <li>
            <strong>Svrha projekta (specifični ciljevi)</strong>
          </li>
        </ul>

        <p>
          1. Povećanje i intenziviranje suradnje MSP-ova u svrhu stvaranja i
          komercijalizacije novih inovativnih proizvoda u ciljanom strateškom
          segmentu
        </p>

        <p>
          2. Povećanje obujma suradnje između MSP-ova kako bi stvaranjem novih
          inovativnih proizvoda i usluga uspostavili dobavljačke odnose sa
          Integratorom i postali dio njihovog lanca vrijednosti
        </p>

        <p>
          3. Povećanje konkurentnosti te održiv rast i razvoj konzorcija
          <strong></strong>
        </p>

        <ul>
          <li>
            <strong>Očekivani rezultati projekta</strong>
          </li>
        </ul>

        <p>
          1.Uspješno uspostavljena suradnja MSP – ova u svrhu stvaranja
          komercijalizacije inovativnih proizvoda
        </p>

        <p>2.Povećan obujam suradnje između MSP – ova</p>

        <p>3.Povećana konkurentnost, održiv rast i razvoj konzorcija</p>

        <p>
          4.Uspješno implementirane inovacije poslovnih procesa i organizacije
          poslovanja
        </p>

        <p>5.Uspješno provedena neovisna revizija projekta</p>

        <p>
          6.U potpunosti ispunjeni zahtjevi vezani za informiranje i vidljivost,
          koje su korisnik i partneri prema Ugovoru obavezni provesti
        </p>

        <p>
          7.Uspješno proveden projekt, pravilno odrađeni administrativni poslovi
          bez zastoja u provedbi te s učinkovitim korištenjem ljudskih,
          vremenskih i financijskih resursa
        </p>

        <ul>
          <li>
            <strong>Ukupna vrijednost projekta iznosi: </strong>
            &nbsp;17.450.101,67 HRK<strong></strong>
          </li>
          <li>
            <strong>Ukupno prihvatljivi troškovi iznose: </strong>14.486.558,73
            HRK
          </li>
          <li>
            <strong>
              Iznos sufinanciranja sa strane Europske unije:&nbsp;
            </strong>
            7.246.087,54 HRK
          </li>
        </ul>

        <p>
          <strong>
            Za više informacija o projektu možete kontaktirati Frane Zdunić na:{" "}
          </strong>
          <strong>
            <a href="mailto:frane.zdunic@intis-engineering.com">
              frane.zdunic@intis-engineering.com
            </a>
          </strong>
          <strong> </strong>
          <strong></strong>
        </p>

        <p>
          <em>
            Za više informacija o Europskim strukturnim i investicijskim
            fondovima posjetite
          </em>
        </p>

        <p>
          <em>
            <u>
              <a href="http://www.strukturnifondovi.hr">
                www.strukturnifondovi.hr
              </a>
            </u>
          </em>
          <em></em>
        </p>

        <p>
          <em>
            Za više informacija o Operativnom programu Konkurentnost i kohezija
            posjetite
          </em>
        </p>

        <p>
          <a href="http://www.strukturnifondovi.hr/op-konkurentnost-i-kohezija-2014-2020-779">
            http://www.strukturnifondovi.hr/op-konkurentnost-i-kohezija-2014-2020-779
          </a>
        </p>
      </Body>
    </AnnualStyle>
  )
}

export default IndexPage
